import { useMutation } from '@apollo/client';
import React from 'react';
import { animated } from 'react-spring';
import styled from 'styled-components';
import {
  createLeadMutation,
  SAVE_DIGGER_ID_MUTATION
} from '../../../../client/__graphql__/mutations';
import { SiteContext } from '../../../utils/context/SiteContext';
import { DiggerType, useDiggerId } from '../../../utils/hooks/useDiggerId';
import { useFadeTransition } from '../../../utils/hooks/useFadeTransition';
import { useForm } from '../../../utils/hooks/useForm';
import { useFormTransition } from '../../../utils/hooks/useFormTransition';
import { Seo } from '../../../utils/seo/Index';
import { getDiggerData } from '../../../utils/tracking/digger';
import { createGTMEvent } from '../../../utils/tracking/gtm';
import {
  ILeadInput,
  ILeadResponse,
  ISaveDiggerInput,
  ISaveDiggerResponse
} from '../../../__types__/global';
import { Container } from '../../layout/Container';
import { Section } from '../../layout/Section';
import { Thanks } from '../../layout/Thanks';
import { AccordionGeneral } from '../../partials/AccordionGeneral';
import { Button } from '../../ui/button/Button';
import { ButtonGroup } from '../../ui/button/ButtonGroup';
import { Form, FormRow } from '../../ui/form/Form';
import { FormError } from '../../ui/form/FormError';
import { Input } from '../../ui/form/Input';
import { ButtonLink } from '../../ui/link/ButtonLink';
import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';

const Voice = (props: { location: any }) => {
  const [posted, setPosted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { routeConfig }: any = React.useContext(SiteContext);
  const { submitWithDigger } = useDiggerId();

  const { fadeIn: pageFadeIn } = useFadeTransition({
    immidiate: true
  });
  const { fadeIn } = useFormTransition({
    posted
  });

  const VALUATION_MUTATION = createLeadMutation({
    valuation: {
      response: `{
        success
        data {
          id
          address
          zip
          phone
          email
        }
      }`
    }
  });

  const [lead, { error }] = useMutation<ILeadResponse, ILeadInput>(
    VALUATION_MUTATION,
    {
      onCompleted: ({ lead }) => {
        setLoading(false);

        if (lead.valuation?.success) {
          window.scroll(0, 0);
          setPosted(true);

          // GTM tracking

          createGTMEvent({
            event: 'pmFormSubmission',
            gaEvent: 'Verdivurdering NY',
            gaCategory: 'Form',
            gaAction: 'Submit'
          });
        }
      }
    }
  );

  const { fields, getFields, handleSubmit, handleChange } = useForm({
    fields: [
      {
        type: 'text',
        name: 'zip',
        placeholder: 'Eks. 1234',
        value: '',
        label: 'Postnummer *',
        required: true
      },
      {
        type: 'tel',
        name: 'phone',
        placeholder: 'Eks. 00000000',
        value: '',
        label: 'Telefon *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'email',
        name: 'email',
        placeholder: 'Eks. ole@mail.no',
        value: '',
        label: 'E-post *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      }
    ],
    submit: () => {
      const preparedFields: any = getFields(fields);
      setLoading(true);
      submitWithDigger(
        DiggerType.VERDIVURDERING,
        {
          zip: preparedFields.zip
        },
        (data) => {
          lead({
            variables: {
              input: {
                ...preparedFields,
                diggerId: data.diggerId,
                referer: `${location.pathname}${location.search}`,
                leadType: 'VERDIVURDERING',
                pageType: 'GENERELL'
              }
            }
          });
        }
      );
    }
  });

  return (
    <>
      <Seo
        title="PrivatMegleren Voice - Prisestimat med ukentlige oppdateringer"
        description=""
        image=""
        url={props.location.pathname}
      />
      <Container>
        <Wrapper style={pageFadeIn}>
          <Section>
            <Top />
            <VoiceText style={{ textAlign: 'center' }}>
              <Heading tag="h1" center>
                Prisestimat med ukentlige oppdateringer
              </Heading>
              <p>
                Eiendom Norge anbefaler deg å følge med på omsetninger der du
                bor og området du ønsker å flytte til, når du skal kjøpe og
                selge eiendom. Det gir deg et oppdatert bilde på hva du kan
                forvente å få, og hva du må forvente å betale. Med vår
                stemmestyrte tjeneste på Google Assistant, kan du raskt estimere
                prisen på egen eiendom eller eiendommer du er nysgjerrig på.
                Start tjenesten med å si «OK Google – snakk med PrivatMegleren».
                Foretrekker du å bruke tastaturet, kan du gjøre det{' '}
                <a href="https://privatmegleren.no/verdivurdering-boligkalkulator">her</a>.
              </p>
              <p>
                Prisestimatene er basert på salgsprisen til eiendommer i området
                du søker på. Statistikken leveres av Prognosesenteret og
                oppdateres ukentlig.
              </p>
            </VoiceText>
            <VoiceText>
              <Heading tag="h2">E-takst?</Heading>
              <p>
                Vi har laget denne tjenesten for at det skal være enkelt for deg
                å følge prisutviklingen i eiendomsmarkedet. Dersom du ønsker en
                presis verdivurdering, til refinansiering eller når du er klar
                for å selge, hjelper vi deg gjerne med det. Send oss skjemaet
                under, så vil en av våre lokalkjente meglere kontakte deg.
              </p>
            </VoiceText>
            <Form onSubmit={handleSubmit} noValidate>
              {error &&
                error.graphQLErrors.map((error, index) => (
                  <FormError key={`error_${index}`} message={error.message} />
                ))}
              {fields.map((item, index) => {
                return (
                  <FormRow key={`field_${index}`}>
                    <Input
                      type={item.type}
                      name={item.name}
                      label={item.label}
                      labelAfter={item.labelAfter}
                      placeholder={item.placeholder}
                      value={item.value}
                      onChange={handleChange}
                      error={item.error}
                    />
                  </FormRow>
                );
              })}
              <ButtonGroup>
                <Button
                  type="primary"
                  colorTheme="gold"
                  disabled={loading}
                  loading={loading}
                >
                  Kontakt meg
                </Button>
                <ButtonLink
                  as="a"
                  href={routeConfig.personvern.url}
                  className="privacy-link-mobile"
                >
                  Personvernpolicy
                </ButtonLink>
              </ButtonGroup>
            </Form>
          </Section>
        </Wrapper>
        <Thanks style={fadeIn}>
          <Section className="form-section">
            <Heading tag="h1" center={true}>
              Tusen takk
            </Heading>
            <Paragraph center={true}>
              En eiendomsmegler vil ta kontakt med deg så snart som mulig.
            </Paragraph>
            <Paragraph center={true}>
              I mellomtiden kan du lese mer om salg av eiendom her.
            </Paragraph>
          </Section>
        </Thanks>
      </Container>
    </>
  );
};

export default Voice;

const Wrapper = styled(animated.div)``;

const Top = styled.div`
  background-image: url(https://cdn.reeltime.no/pm_assets/img/bakgrunner/voicemik.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 58vh;
  margin: 35px 0 0;
  @media (min-width: 600px) {
    margin: 35px 0 75px;
  }
`;

const VoiceText = styled.div`
  margin: 0 auto 4em;
  max-width: 550px;
`;
